// --- VARIABLES ---

\:root
  --accent: red
  --placeholder: gray

// --- DARK MODE ---

@media (prefers-color-scheme: dark)
  :root
    --placeholder: antiquewhite

  body
    background-color: #2d2d2d
    color: var(--placeholder)

// --- GLOBAL ---

body
  font-family: Neucha-Regular, sans-serif

a
  text-decoration: none
  color: var(--accent)

section
  width: 300px
  margin: 60px auto 20px

input[type="range"]
  -webkit-appearance: none
  height: 7px
  border-radius: 5px
  background: rgba(255, 255, 255, 0.6) linear-gradient(var(--accent), var(--accent)) no-repeat
  border-color: var(--accent) !important

input[type="range"]::-webkit-slider-thumb
  -webkit-appearance: none
  height: 20px
  width: 20px
  border-radius: 40%
  background: var(--accent)
  cursor: ew-resize
  box-shadow: 0 0 2px 0 #555
  transition: background .3s ease-in-out

select, input
  border-style: solid
  border-color: lightgrey
  outline: none

input[type="range"], a
  border-style: solid
  border-color: transparent
  outline: none

select:focus, input:focus, a:focus
  //border-style: solid
  //border-color: $accent
  outline: none

// --- HEADER ---

.language-selector
  margin-top: 40px
  width: 100%
  display: flex
  justify-content: center

  .spacer
    margin: auto 15px

h1
  font-family: Just-Overthink, sans-serif
  font-size: 4em
  text-align: center
  margin-top: 40px
  margin-bottom: 30px

// --- MAIN ---

.playback
  width: 185px
  margin: 60px auto
  position: relative
  background-color: transparent
  z-index: 20

  .play
    height: 150px
    width: 150px
    background-image: url("./img/play.svg")
    border-radius: 50%
    margin: auto
    cursor: pointer

  .skip
    height: 65px
    width: 65px
    background-image: url("./img/skip.svg")
    border-radius: 50%
    margin: auto
    cursor: pointer
    position: absolute
    right: 0
    bottom: 0

.input
  position: relative
  width: 100%
  margin: -275px auto 40px

  input
    width: 100%
    height: 100px
    text-align: center
    background-image: url("./img/textbox.svg")
    background-position: center bottom
    color: var(--placeholder)
    font-size: 2.5em
    padding: 275px 0 0 0
    font-family: Neucha-Regular, sans-serif
    position: relative
    z-index: 10

  label
    width: 100%
    text-align: center
    position: absolute
    font-size: 1.2em
    bottom: 30px
    color: var(--placeholder)

.img-background
  background-color: transparent
  background-size: contain
  background-repeat: no-repeat
  border-color: transparent
  display: block

// --- SETTINGS ---

.settings
  > div
    margin-top: 50px

  input, select
    display: block
    width: 100%
    margin-top: 15px
    margin-left: -3px

  .range-label
    display: flex
    width: 325px
    justify-content: space-between
    padding-top: 5px

    label:nth-child(2)
      padding-left: 18px

    label:nth-child(3)
      padding-left: 10px

// --- STATS ---

.stats
  .row
    display: flex
    justify-content: space-between
    margin-top: 5px
    font-size: 0.8em


  .indented
    margin-top: 0
    span:nth-child(odd)
      margin-left: 20px

// --- FOOTER ---

.credits
  text-align: center

  p
    margin-bottom: 10px

// --- FONTS ---

@font-face
  font-family: 'Neucha-Regular'
  src: url('./fonts/Neucha-Regular.ttf') format('trueType')
  font-weight: normal
  font-style: normal

@font-face
  font-family: 'Just-Overthink'
  src: url('./fonts/Just-Overthink.ttf') format('trueType')
  font-weight: normal
  font-style: normal