:root {
  --accent: red;
  --placeholder: gray;
}

@media (prefers-color-scheme: dark) {
  :root {
    --placeholder: antiquewhite;
  }

  body {
    color: var(--placeholder);
    background-color: #2d2d2d;
  }
}

body {
  font-family: Neucha-Regular, sans-serif;
}

a {
  color: var(--accent);
  text-decoration: none;
}

section {
  width: 300px;
  margin: 60px auto 20px;
}

input[type="range"] {
  -webkit-appearance: none;
  background: #fff9 linear-gradient(var(--accent), var(--accent)) no-repeat;
  border-radius: 5px;
  height: 7px;
  border-color: var(--accent) !important;
}

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: var(--accent);
  cursor: ew-resize;
  border-radius: 40%;
  width: 20px;
  height: 20px;
  transition: background .3s ease-in-out;
  box-shadow: 0 0 2px #555;
}

select, input {
  border-style: solid;
  border-color: #d3d3d3;
  outline: none;
}

input[type="range"], a {
  border-style: solid;
  border-color: #0000;
  outline: none;
}

select:focus, input:focus, a:focus {
  outline: none;
}

.language-selector {
  justify-content: center;
  width: 100%;
  margin-top: 40px;
  display: flex;
}

.language-selector .spacer {
  margin: auto 15px;
}

h1 {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 30px;
  font-family: Just-Overthink, sans-serif;
  font-size: 4em;
}

.playback {
  z-index: 20;
  background-color: #0000;
  width: 185px;
  margin: 60px auto;
  position: relative;
}

.playback .play {
  cursor: pointer;
  background-image: url("play.9a1b42c6.svg");
  border-radius: 50%;
  width: 150px;
  height: 150px;
  margin: auto;
}

.playback .skip {
  cursor: pointer;
  background-image: url("skip.c2b785e5.svg");
  border-radius: 50%;
  width: 65px;
  height: 65px;
  margin: auto;
  position: absolute;
  bottom: 0;
  right: 0;
}

.input {
  width: 100%;
  margin: -275px auto 40px;
  position: relative;
}

.input input {
  text-align: center;
  color: var(--placeholder);
  z-index: 10;
  background-image: url("textbox.2d7c93d2.svg");
  background-position: bottom;
  width: 100%;
  height: 100px;
  padding: 275px 0 0;
  font-family: Neucha-Regular, sans-serif;
  font-size: 2.5em;
  position: relative;
}

.input label {
  text-align: center;
  color: var(--placeholder);
  width: 100%;
  font-size: 1.2em;
  position: absolute;
  bottom: 30px;
}

.img-background {
  background-color: #0000;
  background-repeat: no-repeat;
  background-size: contain;
  border-color: #0000;
  display: block;
}

.settings > div {
  margin-top: 50px;
}

.settings input, .settings select {
  width: 100%;
  margin-top: 15px;
  margin-left: -3px;
  display: block;
}

.settings .range-label {
  justify-content: space-between;
  width: 325px;
  padding-top: 5px;
  display: flex;
}

.settings .range-label label:nth-child(2) {
  padding-left: 18px;
}

.settings .range-label label:nth-child(3) {
  padding-left: 10px;
}

.stats .row {
  justify-content: space-between;
  margin-top: 5px;
  font-size: .8em;
  display: flex;
}

.stats .indented {
  margin-top: 0;
}

.stats .indented span:nth-child(odd) {
  margin-left: 20px;
}

.credits {
  text-align: center;
}

.credits p {
  margin-bottom: 10px;
}

@font-face {
  font-family: Neucha-Regular;
  src: url("Neucha-Regular.c27699e3.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: Just-Overthink;
  src: url("Just-Overthink.db6e6dc2.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

/*# sourceMappingURL=index.a0d3be9d.css.map */
